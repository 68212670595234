import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Link } from "gatsby"


class LoginPage extends React.Component {



  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = { password: '', renderError: false};
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.renderError = this.renderError.bind(this);
  }

  async handleClick(event) {
    // send async POST request to login which checks for correctness, sets cookie and returns 200 with json true so I re-direct to home. Otherwise I display error message here (which disappears on handleChange)
    event.preventDefault()
    var data = {'password': this.state.password}
    const response = await fetch('/login', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    if (response.status == 200) {
      window.location = '/';
    } else {
      this.setState({renderError:true})
    }
  }

  handleChange(event) {
    this.setState({password: event.target.value, renderError:false});
  }

  renderError() {
    if (this.state.renderError) {
      return (
      <div>please enter the correct password</div>
      )
    }
  }

  render() {
    return (
      <Layout>
        <div id="main">
          <h1>Log in</h1>
            <div className="row">
              <div className="12u">
                <form>
                  <div className="row uniform 100%">
                    <div className="8u" style={{paddingTop:"2.5em"}}>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <input type="submit" value="Login" onClick={this.handleClick} style={{marginTop:"10px"}}/>
                </form>
                {this.renderError()}
              </div>
            </div>
        </div>
      </Layout>
    );
  }
}
export default LoginPage
